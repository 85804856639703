import React, { useState, useEffect, useRef } from 'react';
import { db } from '../firebase';
import { collection, getDocs, getDoc, query, where, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { Container, Row, Col, Card, Form, Button, InputGroup, FormControl, Dropdown, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt, FaArrowUp, FaCheck, FaPlus } from 'react-icons/fa';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

function RestaurantPage() {
  const { restaurantId } = useParams();
  const [foodItems, setFoodItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortCriteria, setSortCriteria] = useState({ attribute: '', order: '' });
  const [editingItemId, setEditingItemId] = useState(null);
  const [editValues, setEditValues] = useState({});
  const [editingIngredients, setEditingIngredients] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const { currentUser, isAdmin } = useAuth(); 
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false); 

  const [selectedItems, setSelectedItems] = useState([]);
  const [totals, setTotals] = useState({
    calories: 0,
    fats: 0,
    carbs: 0,
    proteins: 0,
    sodium: 0,
    cholesterol: 0,
    sugars: 0,
    fiber: 0,
  });

  const [addedItems, setAddedItems] = useState([]); 

  const [currentRestaurantName, setCurrentRestaurantName] = useState('');
  const [collapsedItems, setCollapsedItems] = useState({});
  const [showSelectedItemsTab, setShowSelectedItemsTab] = useState(false);
  const [currentSelectedItem, setCurrentSelectedItem] = useState(null);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openCalendar, setOpenCalendar] = useState(false);

  const itemRefs = useRef({});
  const auth = getAuth();

  useEffect(() => {
    const formattedDate = selectedDate.toLocaleDateString('en-US');
    const userId = currentUser?.uid;
    const savedItems = userId
      ? JSON.parse(localStorage.getItem(`selectedItems-${userId}-${formattedDate}`)) || []
      : [];
    setSelectedItems(savedItems);
    updateTotals(savedItems);
  }, [selectedDate, currentUser]);

  useEffect(() => {
    const formattedDate = selectedDate.toLocaleDateString('en-US');
    const userId = currentUser?.uid;
    if (userId) {
      localStorage.setItem(`selectedItems-${userId}-${formattedDate}`, JSON.stringify(selectedItems));
    }
  }, [selectedItems, selectedDate, currentUser]);

  useEffect(() => {
    const fetchFoodItems = async () => {
      try {
        const foodItemsCollection = collection(db, 'foodItems');
        const q = query(foodItemsCollection, where('restaurantId', '==', restaurantId));
        const foodItemsSnapshot = await getDocs(q);
        const foodItemsList = foodItemsSnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .sort((a, b) => (b.createdAt?.toMillis() || 0) - (a.createdAt?.toMillis() || 0));

        setFoodItems(foodItemsList);
      } catch (error) {
        console.error('Error fetching food items:', error);
      }
    };

    const fetchRestaurantName = async () => {
      try {
        const restaurantDocRef = doc(db, 'restaurants', restaurantId);
        const restaurantDoc = await getDoc(restaurantDocRef);
        if (restaurantDoc.exists()) {
          setCurrentRestaurantName(restaurantDoc.data().restaurantName);
        }
      } catch (error) {
        console.error('Error fetching restaurant name:', error);
      }
    };

    const fetchCategories = async () => {
      try {
        const categoriesCollection = collection(db, 'categories');
        const q = query(categoriesCollection, where('restaurantId', '==', restaurantId));
        const categoriesSnapshot = await getDocs(q);
        const categoriesList = categoriesSnapshot.docs.map((doc) => doc.data().categoryName);
        setCategories([...new Set(categoriesList)]);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchFoodItems();
    fetchCategories();
    fetchRestaurantName();
  }, [restaurantId]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSort = (attribute) => {
    if (sortCriteria.attribute === attribute) {
      if (sortCriteria.order === 'asc') {
        setSortCriteria({ attribute, order: 'desc' });
      } else if (sortCriteria.order === 'desc') {
        setSortCriteria({ attribute: '', order: '' });
      } else {
        setSortCriteria({ attribute, order: 'asc' });
      }
    } else {
      setSortCriteria({ attribute, order: 'asc' });
    }
  };

  const handleClearSort = () => {
    setSortCriteria({ attribute: '', order: '' });
  };

  const handleEdit = (item) => {
    setEditingItemId(item.id);
    setEditValues({
      itemName: item.itemName,
      servingSize: item.servingSize,
      totalCalories: item.totalCalories,
      totalFats: item.totalFats,
      totalCarbs: item.totalCarbs,
      totalProteins: item.totalProteins,
      totalSodium: item.totalSodium,
      totalCholesterol: item.totalCholesterol,
      totalSugar: item.totalSugar,
      totalFiber: item.totalFiber,
    });
    setEditingIngredients(item.ingredients || []);
  };

  const handleDelete = async (itemId) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        await deleteDoc(doc(db, 'foodItems', itemId));
        setFoodItems(foodItems.filter((item) => item.id !== itemId));
        alert('Item deleted successfully.');
      } catch (error) {
        console.error('Error deleting item:', error);
        alert('Error deleting item.');
      }
    }
  };

  const handleSave = async (itemId) => {
    try {
      const itemDoc = doc(db, 'foodItems', itemId);
      await updateDoc(itemDoc, { ...editValues, ingredients: editingIngredients });
      setFoodItems(
        foodItems.map((item) =>
          item.id === itemId ? { ...item, ...editValues, ingredients: editingIngredients } : item
        )
      );
      setEditingItemId(null);
      alert('Item updated successfully.');
    } catch (error) {
      console.error('Error updating item:', error);
      alert('Error updating item.');
    }
  };

  const handleIngredientChange = (index, value) => {
    const newIngredients = [...editingIngredients];
    newIngredients[index] = value;
    setEditingIngredients(newIngredients);
  };

  const handleAddIngredient = () => {
    setEditingIngredients([...editingIngredients, '']);
  };

  const handleDeleteIngredient = (index) => {
    const newIngredients = editingIngredients.filter((_, i) => i !== index);
    setEditingIngredients(newIngredients);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const updateTotals = (items) => {
    const newTotals = items.reduce(
      (acc, item) => ({
        calories: acc.calories + parseFloat(item.totalCalories || 0) * item.quantity,
        fats: acc.fats + parseFloat(item.totalFats || 0) * item.quantity,
        carbs: acc.carbs + parseFloat(item.totalCarbs || 0) * item.quantity,
        proteins: acc.proteins + parseFloat(item.totalProteins || 0) * item.quantity,
        sodium: acc.sodium + parseFloat(item.totalSodium || 0) * item.quantity,
        cholesterol: acc.cholesterol + parseFloat(item.totalCholesterol || 0) * item.quantity,
        sugars: acc.sugars + parseFloat(item.totalSugar || 0) * item.quantity,
        fiber: acc.fiber + parseFloat(item.totalFiber || 0) * item.quantity,
      }),
      {
        calories: 0,
        fats: 0,
        carbs: 0,
        proteins: 0,
        sodium: 0,
        cholesterol: 0,
        sugars: 0,
        fiber: 0,
      }
    );

    setTotals(newTotals);
  };

  const handleAddToCalculator = (item) => {
    const existingItemIndex = selectedItems.findIndex(selectedItem => selectedItem.id === item.id);

    if (existingItemIndex !== -1) {
      const newSelectedItems = selectedItems.filter((_, i) => i !== existingItemIndex);
      setSelectedItems(newSelectedItems);
      updateTotals(newSelectedItems);
      setAddedItems((prevItems) => prevItems.filter((id) => id !== item.id)); 
    } else {
      const newSelectedItems = [...selectedItems, { ...item, quantity: 1, restaurantName: currentRestaurantName }];
      setSelectedItems(newSelectedItems);
      updateTotals(newSelectedItems);
      setAddedItems((prevItems) => [...prevItems, item.id]); 
    }
  };

  const handleRemoveFromCalculator = (index) => {
    const itemId = selectedItems[index].id;
    const newSelectedItems = selectedItems.filter((_, i) => i !== index);
    setSelectedItems(newSelectedItems);
    updateTotals(newSelectedItems);
    setAddedItems((prevItems) => prevItems.filter((id) => id !== itemId)); 
  };

  const handleQuantityChange = (index, quantity) => {
    const newSelectedItems = [...selectedItems];
    newSelectedItems[index].quantity = quantity;
    setSelectedItems(newSelectedItems);
    updateTotals(newSelectedItems);
  };

  const toggleCollapse = (index) => {
    setCollapsedItems(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleItemClick = (item) => {
    setShowSelectedItemsTab(true);
    setCurrentSelectedItem(item);
    setEditingItemId(null); 
  };

  const resetView = () => {
    setShowSelectedItemsTab(false);
    setCurrentSelectedItem(null);
    setEditingItemId(null); 
  };

  const getFilteredItems = () => {
    let items = foodItems;

    if (selectedCategory) {
      items = items.filter(item => item.itemCategory === selectedCategory);
    }

    items = items.filter(item => item.itemName.toLowerCase().includes(searchTerm.toLowerCase()));

    if (sortCriteria.attribute) {
      items = items.sort((a, b) => {
        if (sortCriteria.order === 'asc') {
          return a[sortCriteria.attribute] - b[sortCriteria.attribute];
        } else if (sortCriteria.order === 'desc') {
          return b[sortCriteria.attribute] - a[sortCriteria.attribute];
        }
        return 0;
      });
    }

    return items;
  };

  const handlePreviousDay = () => {
    setSelectedDate(prevDate => new Date(prevDate.setDate(prevDate.getDate() - 1)));
  };

  const handleNextDay = () => {
    setSelectedDate(prevDate => new Date(prevDate.setDate(prevDate.getDate() + 1)));
  };

  const handleCalendarClick = () => {
    setOpenCalendar(true);
  };

  const handleLogin = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log('Logged in as:', result.user.email);
        setShowLoginModal(false);
      })
      .catch((error) => {
        console.error('Error logging in:', error);
      });
  };

  const handleShowLoginModal = () => setShowLoginModal(true);
  const handleCloseLoginModal = () => setShowLoginModal(false);

  return (
    <Container>
      <h1 className="my-4 text-center">Restaurant Items</h1>
      {currentRestaurantName && (
        <h3 className="text-center text-muted">{currentRestaurantName}</h3>
      )}
      <Form className="mb-4">
        <Form.Control
          type="text"
          placeholder="Search food items..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </Form>
      <div className="mb-4">
        <Dropdown className="mb-3">
          <Dropdown.Toggle variant="success" id="category-dropdown">
            {selectedCategory || 'Select Category'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleCategoryChange('')}>All Categories</Dropdown.Item>
            {categories.map((category) => (
              <Dropdown.Item key={category} onClick={() => handleCategoryChange(category)}>
                {category}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Button
          variant={sortCriteria.attribute === 'totalCalories' && sortCriteria.order ? 'success' : 'outline-primary'}
          className="me-2 mb-2"
          onClick={() => handleSort('totalCalories')}
        >
          Calories {sortCriteria.attribute === 'totalCalories' ? (sortCriteria.order === 'asc' ? '↓' : '↑') : ''}
        </Button>
        <Button
          variant={sortCriteria.attribute === 'totalFats' && sortCriteria.order ? 'success' : 'outline-primary'}
          className="me-2 mb-2"
          onClick={() => handleSort('totalFats')}
        >
          Fats {sortCriteria.attribute === 'totalFats' ? (sortCriteria.order === 'asc' ? '↓' : '↑') : ''}
        </Button>
        <Button
          variant={sortCriteria.attribute === 'totalCarbs' && sortCriteria.order ? 'success' : 'outline-primary'}
          className="me-2 mb-2"
          onClick={() => handleSort('totalCarbs')}
        >
          Carbs {sortCriteria.attribute === 'totalCarbs' ? (sortCriteria.order === 'asc' ? '↓' : '↑') : ''}
        </Button>
        <Button
          variant={sortCriteria.attribute === 'totalProteins' && sortCriteria.order ? 'success' : 'outline-primary'}
          className="me-2 mb-2"
          onClick={() => handleSort('totalProteins')}
        >
          Protein {sortCriteria.attribute === 'totalProteins' ? (sortCriteria.order === 'asc' ? '↓' : '↑') : ''}
        </Button>
        <Button
          variant={sortCriteria.attribute === 'totalSodium' && sortCriteria.order ? 'success' : 'outline-primary'}
          className="me-2 mb-2"
          onClick={() => handleSort('totalSodium')}
        >
          Sodium {sortCriteria.attribute === 'totalSodium' ? (sortCriteria.order === 'asc' ? '↓' : '↑') : ''}
        </Button>
        <Button
          variant={sortCriteria.attribute === 'totalCholesterol' && sortCriteria.order ? 'success' : 'outline-primary'}
          className="me-2 mb-2"
          onClick={() => handleSort('totalCholesterol')}
        >
          Cholesterol {sortCriteria.attribute === 'totalCholesterol' ? (sortCriteria.order === 'asc' ? '↓' : '↑') : ''}
        </Button>
        <Button
          variant={sortCriteria.attribute === 'totalSugar' && sortCriteria.order ? 'success' : 'outline-primary'}
          className="me-2 mb-2"
          onClick={() => handleSort('totalSugar')}
        >
          Sugars {sortCriteria.attribute === 'totalSugar' ? (sortCriteria.order === 'asc' ? '↓' : '↑') : ''}
        </Button>
        <Button
          variant={sortCriteria.attribute === 'totalFiber' && sortCriteria.order ? 'success' : 'outline-primary'}
          className="me-2 mb-2"
          onClick={() => handleSort('totalFiber')}
        >
          Fiber {sortCriteria.attribute === 'totalFiber' ? (sortCriteria.order === 'asc' ? '↓' : '↑') : ''}
        </Button>
        <Button
          variant="outline-secondary"
          className="ms-3 mb-2"
          onClick={handleClearSort}
        >
          Clear
        </Button>
      </div>
      <Row>
        <Col xs={12} lg={4} className="mb-4 order-1 order-lg-3">
          <Card className="h-100 shadow-sm" style={{ maxHeight: '500px', overflowY: 'auto', padding: '15px' }}>
            <Card.Body style={{ padding: '0', margin: '0' }}>
              <h5 style={{ width: '100%', textAlign: 'center' }}>Nutritional Calculator</h5>
              <div className="d-flex justify-content-center align-items-center mb-3">
                <Button variant="outline-secondary" onClick={handlePreviousDay} style={{ margin: '0 10px' }}>←</Button>
                <div className="d-flex align-items-center">
                  <DatePicker
                    selected={selectedDate}
                    onChange={date => setSelectedDate(date)}
                    open={openCalendar}
                    onClickOutside={() => setOpenCalendar(false)}
                    onSelect={() => setOpenCalendar(false)}
                    className="form-control"
                    style={{ width: '140px', textAlign: 'center' }}
                  />
                  <FaCalendarAlt size={24} style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={handleCalendarClick} />
                </div>
                <Button variant="outline-secondary" onClick={handleNextDay} style={{ margin: '0 10px' }}>→</Button>
              </div>
              <hr />
              <div
                className="d-flex flex-column align-items-center justify-content-center text-center mb-3"
                style={{
                  backgroundColor: '#d0eaff',
                  color: 'black',
                  padding: '15px 20px',
                  borderRadius: '20px',
                  margin: '0 auto 10px auto',
                  width: '120px',
                  height: '50px',
                  fontSize: '1em',
                }}
              >
                <strong>Calories</strong>
                <span>{totals.calories.toFixed(0)}</span>
              </div>
              <div className="d-flex flex-wrap justify-content-start mb-3" style={{ width: '100%' }}>
                {[
                  { label: 'Fats', value: `${totals.fats.toFixed(0)} g` },
                  { label: 'Carbs', value: `${totals.carbs.toFixed(0)} g` },
                  { label: 'Proteins', value: `${totals.proteins.toFixed(0)} g` },
                  { label: 'Sodium', value: `${totals.sodium.toFixed(0)} mg` },
                  { label: 'Sugars', value: `${totals.sugars.toFixed(0)} g` },
                  { label: 'Cholesterol', value: `${totals.cholesterol.toFixed(0)} mg` },
                  { label: 'Fiber', value: `${totals.fiber.toFixed(0)} g` }
                ].map((nutrient, index) => (
                  <div
                    key={index}
                    className="d-flex flex-column align-items-center justify-content-center text-center"
                    style={{
                      backgroundColor: '#d0eaff',
                      color: 'black',
                      padding: '10px 20px',
                      borderRadius: '20px',
                      margin: '5px',
                      width: '110px',
                      height: '40px',
                      fontSize: '0.8em',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    <strong>{nutrient.label}</strong>
                    <span>{nutrient.value}</span>
                  </div>
                ))}
              </div>
              {selectedItems.length === 0 ? (
                <p className="text-center">No items selected.</p>
              ) : (
                <ul className="list-unstyled">
                  {selectedItems.map((item, index) => (
                    <li key={index} className="mb-4" style={{ padding: '10px', backgroundColor: '#f8f9fa', borderRadius: '5px' }}>
                      <div
                        className="d-flex justify-content-between align-items-center mb-2"
                        onClick={() => toggleCollapse(index)}
                        style={{ cursor: 'pointer' }}
                      >
                        <span
                          className="font-weight-bold"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleItemClick(item);
                          }}
                          style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
                        >
                          {item.itemName}
                        </span>
                        <Button variant="danger" size="sm" onClick={(e) => { e.stopPropagation(); handleRemoveFromCalculator(index); resetView(); }}>x</Button>
                      </div>
                      <p className="text-muted" style={{ fontSize: '0.9em', margin: '5px 0' }}>{item.restaurantName}</p>
                      {!collapsedItems[index] && (
                        <div style={{ padding: '10px', backgroundColor: '#e9ecef', borderRadius: '5px', width: '100%' }}>
                          <div className="d-flex align-items-center mb-2">
                            <FormControl
                              type="number"
                              value={item.quantity}
                              onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                              style={{ maxWidth: '60px', marginRight: '10px' }}
                              min="1"
                            />
                            <span>Quantity</span>
                          </div>
                          <p><strong>Total Calories:</strong> {parseFloat(item.totalCalories) * item.quantity}</p>
                          <p><strong>Total Fats:</strong> {parseFloat(item.totalFats) * item.quantity} g</p>
                          <p><strong>Total Carbs:</strong> {parseFloat(item.totalCarbs) * item.quantity} g</p>
                          <p><strong>Total Proteins:</strong> {parseFloat(item.totalProteins) * item.quantity} g</p>
                          <p><strong>Total Sodium:</strong> {parseFloat(item.totalSodium) * item.quantity} mg</p>
                          <p><strong>Total Cholesterol:</strong> {parseFloat(item.totalCholesterol) * item.quantity} mg</p>
                          <p><strong>Total Sugars:</strong> {parseFloat(item.totalSugar) * item.quantity} g</p>
                          <p><strong>Total Fiber:</strong> {parseFloat(item.totalFiber) * item.quantity} g</p>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              )}
              {!currentUser && (
                <div
                  className="blur-overlay d-flex flex-column justify-content-center align-items-center"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    backdropFilter: 'blur(4px)',
                    textAlign: 'center',
                    padding: '10px',
                    color: 'black',
                    zIndex: 1,
                    borderRadius: '5px'
                  }}
                >
                  <p style={{ fontSize: '1.2em', fontWeight: 'bold', marginBottom: '10px' }}>
                    Please log in to use the Nutritional Calculator
                  </p>
                  <Button variant="primary" onClick={handleShowLoginModal}>Login</Button>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={8} className="order-2 order-lg-2">
          <Row>
            {showSelectedItemsTab && currentSelectedItem ? (
              <Col xs={12} className="mb-4">
                <Button onClick={resetView} className="mb-3" variant="secondary">Show All Items</Button>
                <Card className="h-100 shadow-sm">
                  <Card.Body>
                    {editingItemId === currentSelectedItem.id ? (
                      <>
                        <InputGroup className="mb-2">
                          <FormControl
                            placeholder="Item Name"
                            value={editValues.itemName || ''}
                            onChange={(e) => setEditValues({ ...editValues, itemName: e.target.value })}
                          />
                        </InputGroup>
                        {editingIngredients.map((ingredient, idx) => (
                          <InputGroup className="mb-2" key={idx}>
                            <FormControl
                              placeholder={`Ingredient ${idx + 1}`}
                              value={ingredient}
                              onChange={(e) => handleIngredientChange(idx, e.target.value)}
                            />
                            <Button variant="danger" onClick={() => handleDeleteIngredient(idx)}>Delete</Button>
                          </InputGroup>
                        ))}
                        <Button variant="secondary" onClick={handleAddIngredient} className="mb-3">
                          Add Ingredient
                        </Button>
                        <div className="d-flex justify-content-between mt-3">
                          <Button variant="primary" onClick={() => handleSave(currentSelectedItem.id)}>
                            Save
                          </Button>
                          <Button variant="secondary" onClick={() => setEditingItemId(null)}>
                            Cancel
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="d-flex justify-content-between align-items-center">
                          <Card.Title className="font-weight-bold">{currentSelectedItem.itemName}</Card.Title>
                          {isAdmin && ( 
                            <div className="d-flex justify-content-start gap-2">
                              <Button variant="warning" onClick={() => handleEdit(currentSelectedItem)}>
                                Edit
                              </Button>
                              <Button variant="danger" onClick={() => handleDelete(currentSelectedItem.id)}>
                                Delete
                              </Button>
                            </div>
                          )}
                        </div>
                        <hr />
                        {currentSelectedItem.ingredients && currentSelectedItem.ingredients.filter(ing => ing.trim() !== "").length > 0 && (
                          <>
                            <h6 className="text-muted" style={{ fontSize: '0.9em' }}>Ingredients:</h6>
                            <div className="d-flex flex-wrap mb-3">
                              {currentSelectedItem.ingredients.filter(ing => ing.trim() !== "").map((ingredient, index) => (
                                <div key={index} style={{
                                  backgroundColor: 'blue',
                                  color: 'white',
                                  padding: '5px 10px',
                                  borderRadius: '15px',
                                  margin: '5px',
                                  fontSize: '0.8em'
                                }}>
                                  {ingredient}
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                        <Card.Text className="mb-2">
                          <strong>Serving Size:</strong> {currentSelectedItem.servingSize}
                        </Card.Text>
                        <Card.Text className="mb-2">
                          <strong>Total Calories:</strong> {parseFloat(currentSelectedItem.totalCalories)} Calories
                        </Card.Text>
                        <Card.Text className="mb-2">
                          <strong>Total Fats:</strong> {parseFloat(currentSelectedItem.totalFats)} g
                        </Card.Text>
                        <Card.Text className="mb-2">
                          <strong>Total Carbs:</strong> {parseFloat(currentSelectedItem.totalCarbs)} g
                        </Card.Text>
                        <Card.Text className="mb-3">
                          <strong>Total Proteins:</strong> {parseFloat(currentSelectedItem.totalProteins)} g
                        </Card.Text>
                        <Card.Text className="mb-2">
                          <strong>Total Sodium:</strong> {parseFloat(currentSelectedItem.totalSodium)} mg
                        </Card.Text>
                        <Card.Text className="mb-2">
                          <strong>Total Cholesterol:</strong> {parseFloat(currentSelectedItem.totalCholesterol)} mg
                        </Card.Text>
                        <Card.Text className="mb-2">
                          <strong>Total Sugars:</strong> {parseFloat(currentSelectedItem.totalSugar)} g
                        </Card.Text>
                        <Card.Text className="mb-2">
                          <strong>Total Fiber:</strong> {parseFloat(currentSelectedItem.totalFiber)} g
                        </Card.Text>
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            ) : (
              getFilteredItems().map((item) => (
                <Col xs={12} md={6} key={item.id} className="mb-4">
                  <Card ref={el => itemRefs.current[item.id] = el} className="h-100 shadow-sm">
                    <Card.Body>
                      {editingItemId === item.id ? (
                        <>
                          <InputGroup className="mb-2">
                            <FormControl
                              placeholder="Item Name"
                              value={editValues.itemName || ''}
                              onChange={(e) => setEditValues({ ...editValues, itemName: e.target.value })}
                            />
                          </InputGroup>
                          {editingIngredients.map((ingredient, idx) => (
                            <InputGroup className="mb-2" key={idx}>
                              <FormControl
                                placeholder={`Ingredient ${idx + 1}`}
                                value={ingredient}
                                onChange={(e) => handleIngredientChange(idx, e.target.value)}
                              />
                              <Button variant="danger" onClick={() => handleDeleteIngredient(idx)}>Delete</Button>
                            </InputGroup>
                          ))}
                          <Button variant="secondary" onClick={handleAddIngredient} className="mb-3">
                            Add Ingredient
                          </Button>
                          <div className="d-flex justify-content-between mt-3">
                            <Button variant="primary" onClick={() => handleSave(item.id)}>
                              Save
                            </Button>
                            <Button variant="secondary" onClick={() => setEditingItemId(null)}>
                              Cancel
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="d-flex justify-content-between align-items-center">
                            <Card.Title className="font-weight-bold">{item.itemName}</Card.Title>
                            <Button
                              variant={addedItems.includes(item.id) ? 'success' : 'primary'}
                              size="sm"
                              onClick={() => handleAddToCalculator(item)}
                              disabled={!currentUser} 
                            >
                              {addedItems.includes(item.id) ? <FaCheck /> : <FaPlus />}
                            </Button>
                          </div>
                          <hr />
                          {item.ingredients && item.ingredients.filter(ing => ing.trim() !== "").length > 0 && (
                            <>
                              <h6 className="text-muted" style={{ fontSize: '0.9em' }}>Ingredients:</h6>
                              <div className="d-flex flex-wrap mb-3">
                                {item.ingredients.filter(ing => ing.trim() !== "").map((ingredient, index) => (
                                  <div key={index} style={{
                                    backgroundColor: 'blue',
                                    color: 'white',
                                    padding: '5px 10px',
                                    borderRadius: '15px',
                                    margin: '5px',
                                    fontSize: '0.8em'
                                  }}>
                                    {ingredient}
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                          <Card.Text className="mb-2">
                            <strong>Serving Size:</strong> {item.servingSize}
                          </Card.Text>
                          <Card.Text className="mb-2">
                            <strong>Total Calories:</strong> {parseFloat(item.totalCalories)} Calories
                          </Card.Text>
                          <Card.Text className="mb-2">
                            <strong>Total Fats:</strong> {parseFloat(item.totalFats)} g
                          </Card.Text>
                          <Card.Text className="mb-2">
                            <strong>Total Carbs:</strong> {parseFloat(item.totalCarbs)} g
                          </Card.Text>
                          <Card.Text className="mb-3">
                            <strong>Total Proteins:</strong> {parseFloat(item.totalProteins)} g
                          </Card.Text>
                          <Card.Text className="mb-2">
                            <strong>Total Sodium:</strong> {parseFloat(item.totalSodium)} mg
                          </Card.Text>
                          <Card.Text className="mb-2">
                            <strong>Total Cholesterol:</strong> {parseFloat(item.totalCholesterol)} mg
                          </Card.Text>
                          <Card.Text className="mb-2">
                            <strong>Total Sugars:</strong> {parseFloat(item.totalSugar)} g
                          </Card.Text>
                          <Card.Text className="mb-2">
                            <strong>Total Fiber:</strong> {parseFloat(item.totalFiber)} g
                          </Card.Text>
                          {isAdmin && ( 
                            <div className="d-flex justify-content-between mt-3">
                              <Button variant="warning" onClick={() => handleEdit(item)}>
                                Edit
                              </Button>
                              <Button variant="danger" onClick={() => handleDelete(item.id)}>
                                Delete
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              ))
            )}
          </Row>
        </Col>
      </Row>

      <Modal show={showLoginModal} onHide={handleCloseLoginModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>Please log in to use the Nutritional Calculator</p>
          <Button variant="primary" onClick={handleLogin}>Login with Google</Button>
        </Modal.Body>
      </Modal>

      {showBackToTop && (
        <Button
          onClick={handleBackToTop}
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            backgroundColor: 'blue',
            color: 'white',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <FaArrowUp />
        </Button>
      )}
    </Container>
  );
}

export default RestaurantPage;
