// src/pages/HomePage.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { Container, Row, Col, Card, Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

function HomePage() {
  const [restaurants, setRestaurants] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingRestaurantId, setEditingRestaurantId] = useState(null);
  const [newRestaurantName, setNewRestaurantName] = useState('');
  const { isAdmin } = useAuth(); // Use isAdmin for conditional rendering
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const restaurantCollection = collection(db, 'restaurants');
        const restaurantSnapshot = await getDocs(restaurantCollection);
        const restaurantList = restaurantSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRestaurants(restaurantList);
      } catch (error) {
        console.error('Error fetching restaurants:', error);
      }
    };

    fetchRestaurants();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDelete = async (restaurantId) => {
    if (window.confirm("Are you sure you want to delete this restaurant?")) {
      try {
        await deleteDoc(doc(db, 'restaurants', restaurantId));
        setRestaurants(restaurants.filter((restaurant) => restaurant.id !== restaurantId));
        alert('Restaurant deleted successfully.');
      } catch (error) {
        console.error('Error deleting restaurant:', error);
        alert('Error deleting restaurant.');
      }
    }
  };

  const handleEdit = (restaurant) => {
    setEditingRestaurantId(restaurant.id);
    setNewRestaurantName(restaurant.restaurantName);
  };

  const handleSave = async (restaurantId) => {
    try {
      // Ensure the user is an admin before attempting to update
      if (!isAdmin) {
        alert('You do not have permission to update restaurants.');
        return;
      }
  
      const restaurantDoc = doc(db, 'restaurants', restaurantId);
      await updateDoc(restaurantDoc, { restaurantName: newRestaurantName });
      setRestaurants(restaurants.map((restaurant) =>
        restaurant.id === restaurantId ? { ...restaurant, restaurantName: newRestaurantName } : restaurant
      ));
      setEditingRestaurantId(null);
      alert('Restaurant name updated successfully.');
    } catch (error) {
      console.error('Error updating restaurant:', error);
      alert('Error updating restaurant: ' + error.message);
    }
  };
  

  const handleCancel = () => {
    setEditingRestaurantId(null); // Reset the editing state
    setNewRestaurantName(''); // Clear the new restaurant name
  };

  const handleCardClick = (restaurantId) => {
    if (editingRestaurantId === null) { // Only navigate if not in editing mode
      navigate(`/restaurant/${restaurantId}`);
    }
  };

  const filteredRestaurants = restaurants.filter((restaurant) =>
    restaurant.restaurantName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container>
      <h1 className="my-4 text-center">Restaurants</h1>
      <Form className="mb-4">
        <Form.Control
          type="text"
          placeholder="Search restaurants..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </Form>
      <Row>
        {filteredRestaurants.map((restaurant) => (
          <Col key={restaurant.id} md={4} className="mb-4">
            <Card className="text-center" style={{ cursor: 'pointer' }}>
              <Card.Body>
                {editingRestaurantId === restaurant.id ? (
                  <>
                    <InputGroup className="mb-3" onClick={(e) => e.stopPropagation()}>
                      <FormControl
                        value={newRestaurantName}
                        onChange={(e) => setNewRestaurantName(e.target.value)}
                        onClick={(e) => e.stopPropagation()} // Stop card click event
                      />
                    </InputGroup>
                    <div className="d-flex justify-content-center" onClick={(e) => e.stopPropagation()}>
                      <Button
                        variant="success"
                        className="me-2"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent card click
                          handleSave(restaurant.id);
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent card click
                          handleCancel(); // Cancel editing
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <Card.Title onClick={() => handleCardClick(restaurant.id)}>
                      {restaurant.restaurantName}
                    </Card.Title>
                    {isAdmin && (
                      <>
                        <Button
                          variant="warning"
                          className="me-2 mt-3"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent card click
                            handleEdit(restaurant);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          className="mt-3"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent card click
                            handleDelete(restaurant.id);
                          }}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default HomePage;
