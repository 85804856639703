// src/pages/AddRestaurantPage.js
import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { Container, Form, Button, Alert, Spinner } from 'react-bootstrap';
import { useAuth } from '../AuthContext'; // Import useAuth

function AddRestaurantPage() {
  const { isAdmin } = useAuth(); // Use the isAdmin state from AuthContext
  const [restaurantName, setRestaurantName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');
    
    if (!restaurantName.trim()) {
      setErrorMessage('Restaurant name cannot be empty.');
      setLoading(false);
      return;
    }

    try {
      const restaurantCollection = collection(db, 'restaurants');
      await addDoc(restaurantCollection, {
        restaurantName: restaurantName.trim(),
      });
      setSuccessMessage('Restaurant added successfully!');
      setRestaurantName('');
    } catch (error) {
      setErrorMessage('Error adding restaurant: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  if (!isAdmin) {
    return (
      <Container>
        <h1 className="my-4 text-center">Access Denied</h1>
        <p className="text-center">You do not have permission to access this page.</p>
      </Container>
    );
  }

  return (
    <Container>
      <h1 className="my-4 text-center">Add Restaurant</h1>
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Restaurant Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter restaurant name"
            value={restaurantName}
            onChange={(e) => setRestaurantName(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3" disabled={loading}>
          {loading ? (
            <>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Adding...
            </>
          ) : (
            'Add Restaurant'
          )}
        </Button>
      </Form>
    </Container>
  );
}

export default AddRestaurantPage;
