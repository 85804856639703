// src/components/Footer.js
import React from 'react';
import { Container } from 'react-bootstrap';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="footer text-center py-3 mt-auto">
      <Container>
        <hr />
        <p>Connect with us on social media:</p>
        <div>
          <a
            href="https://www.facebook.com/trackameal"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-2"
            style={{ fontSize: '1.5em', color: '#3b5998' }}
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.instagram.com/trackameal"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-2"
            style={{ fontSize: '1.5em', color: '#e4405f' }}
          >
            <FaInstagram />
          </a>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
