// src/App.js

import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import RestaurantPage from './pages/RestaurantPage';
import AdminPage from './pages/AdminPage';
import LoginPage from './pages/LoginPage';
import AddRestaurantPage from './pages/AddRestaurantPage';
import AddItemPage from './pages/AddItemPage';
import ProtectedRoute from './ProtectedRoute';
import Header from './Header';
import Footer from './components/Footer';
import BrowserAlert from './components/BrowserAlert'; // Import the BrowserAlert component

function App() {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    // Check the user agent for Instagram, Facebook, or TikTok
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (
      userAgent.indexOf('FBAN') > -1 ||
      userAgent.indexOf('FBAV') > -1 ||
      userAgent.indexOf('Instagram') > -1 ||
      userAgent.indexOf('TikTok') > -1
    ) {
      setShowAlert(true);
    }
  }, []);

  // Only render the BrowserAlert component if the condition is met
  if (showAlert) {
    return <BrowserAlert />;
  }

  return (
    <div className="d-flex flex-column min-vh-100"> {/* Ensures the app takes full height of viewport */}
      <Header />
      <div className="flex-grow-1"> {/* This div allows content to grow and push the footer to the bottom */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/admin" element={<ProtectedRoute><AdminPage /></ProtectedRoute>} />
          <Route path="/add-restaurant" element={<ProtectedRoute><AddRestaurantPage /></ProtectedRoute>} />
          <Route path="/add-item" element={<ProtectedRoute><AddItemPage /></ProtectedRoute>} />
          <Route path="/restaurant/:restaurantId" element={<RestaurantPage />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
