// src/AdminPage.js
import React, { useState } from 'react';
import { db, storage } from '../firebase';
import { Container, Form, Button } from 'react-bootstrap';

function AdminPage() {
  const [formData, setFormData] = useState({
    restaurantName: '',
    foodItemName: '',
    image: null,
    totalCalories: '',
    servingSize: '',
    totalFats: '',
    totalCarbs: '',
    totalProteins: '',
    totalSugars: '',
    totalFiber: '',
    totalCholesterol: '',
    totalSodium: '',
  });

  const handleChange = e => {
    if (e.target.name === 'image') {
      setFormData({
        ...formData,
        image: e.target.files[0],
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const {
      restaurantName,
      foodItemName,
      image,
      totalCalories,
      servingSize,
      totalFats,
      totalCarbs,
      totalProteins,
      totalSugars,
      totalFiber,
      totalCholesterol,
      totalSodium,
    } = formData;

    // Upload image to Firebase Storage
    const storageRef = storage.ref();
    const fileRef = storageRef.child(image.name);
    await fileRef.put(image);
    const imageUrl = await fileRef.getDownloadURL();

    // Save data to Firestore
    await db.collection('foodItems').add({
      restaurantName,
      foodItemName,
      imageUrl,
      totalCalories,
      servingSize,
      totalFats,
      totalCarbs,
      totalProteins,
      totalSugars,
      totalFiber,
      totalCholesterol,
      totalSodium,
    });

    alert('Food item added successfully!');
    setFormData({
      restaurantName: '',
      foodItemName: '',
      image: null,
      totalCalories: '',
      servingSize: '',
      totalFats: '',
      totalCarbs: '',
      totalProteins: '',
      totalSugars: '',
      totalFiber: '',
      totalCholesterol: '',
      totalSodium: '',
    });
  };

  return (
    <Container>
      <h1 className="my-4 text-center">Add Food Item</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Restaurant Name</Form.Label>
          <Form.Control
            type="text"
            name="restaurantName"
            placeholder="Restaurant Name"
            value={formData.restaurantName}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Food Item Name</Form.Label>
          <Form.Control
            type="text"
            name="foodItemName"
            placeholder="Food Item Name"
            value={formData.foodItemName}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Image</Form.Label>
          <Form.Control type="file" name="image" onChange={handleChange} required />
        </Form.Group>
        <Form.Group>
          <Form.Label>Total Calories</Form.Label>
          <Form.Control
            type="number"
            name="totalCalories"
            placeholder="Total Calories"
            value={formData.totalCalories}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Serving Size</Form.Label>
          <Form.Control
            type="text"
            name="servingSize"
            placeholder="Serving Size"
            value={formData.servingSize}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Total Fats</Form.Label>
          <Form.Control
            type="number"
            name="totalFats"
            placeholder="Total Fats"
            value={formData.totalFats}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Total Carbohydrates</Form.Label>
          <Form.Control
            type="number"
            name="totalCarbs"
            placeholder="Total Carbohydrates"
            value={formData.totalCarbs}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Total Proteins</Form.Label>
          <Form.Control
            type="number"
            name="totalProteins"
            placeholder="Total Proteins"
            value={formData.totalProteins}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Total Sugars</Form.Label>
          <Form.Control
            type="number"
            name="totalSugars"
            placeholder="Total Sugars"
            value={formData.totalSugars}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Total Fiber</Form.Label>
          <Form.Control
            type="number"
            name="totalFiber"
            placeholder="Total Fiber"
            value={formData.totalFiber}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Total Cholesterol</Form.Label>
          <Form.Control
            type="number"
            name="totalCholesterol"
            placeholder="Total Cholesterol"
            value={formData.totalCholesterol}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Total Sodium</Form.Label>
          <Form.Control
            type="number"
            name="totalSodium"
            placeholder="Total Sodium"
            value={formData.totalSodium}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3">
          Add Food Item
        </Button>
      </Form>
    </Container>
  );
}

export default AdminPage;
