// src/Header.js

import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext';

function Header() {
  const { currentUser, isAdmin, logout } = useAuth(); // Use isAdmin to conditionally render admin links

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <Navbar bg="light" expand="lg" className="py-2">
      <Container>
        <Navbar.Brand as={Link} to="/" className="mx-3">Track A Meal</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mx-3" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* Links visible to everyone */}
            <Nav.Link as={Link} to="/" className="mx-2">Home</Nav.Link>
            {/* Links visible only to the admin */}
            {isAdmin && (
              <>
                <Nav.Link as={Link} to="/add-restaurant" className="mx-2">Add Restaurant</Nav.Link>
                <Nav.Link as={Link} to="/add-item" className="mx-2">Add Item</Nav.Link>
              </>
            )}
          </Nav>
          {currentUser ? (
            <Button variant="outline-danger" onClick={handleLogout} className="mx-2">
              Logout
            </Button>
          ) : (
            <Nav.Link as={Link} to="/login" className="mx-2">Login</Nav.Link>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
