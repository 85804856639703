// src/pages/LoginPage.js
import React from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap'; // Import Row and Col for grid system
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const { loginWithGoogle } = useAuth(); // Use the loginWithGoogle function from AuthContext
  const navigate = useNavigate();

  const handleGoogleLogin = async () => {
    try {
      await loginWithGoogle();
      alert('Logged in successfully');
      navigate('/'); // Redirect to the HomePage after login
    } catch (error) {
      alert('Error logging in: ' + error.message);
    }
  };

  return (
    <Container>
      <h1 className="my-4 text-center">Login</h1>
      <Row className="justify-content-center"> {/* Add Row with justify-content-center class */}
        <Col xs="auto"> {/* Use Col to auto-adjust the width and center the button */}
          <Button variant="primary" onClick={handleGoogleLogin} className="mt-3">
            Sign in with Google
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginPage;
