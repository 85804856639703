// src/pages/AddItemPage.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, addDoc, query, where, serverTimestamp } from 'firebase/firestore'; // Import serverTimestamp
import { Container, Form, Button, FormControl, InputGroup, Row, Col, Alert } from 'react-bootstrap';
import { useAuth } from '../AuthContext'; // Import useAuth

function AddItemPage() {
  const { isAdmin } = useAuth(); // Use the isAdmin state from AuthContext
  const [restaurants, setRestaurants] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [itemName, setItemName] = useState('');
  const [itemCategory, setItemCategory] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [servingSize, setServingSize] = useState('');
  const [totalCalories, setTotalCalories] = useState('');
  const [totalFats, setTotalFats] = useState('');
  const [totalCarbs, setTotalCarbs] = useState('');
  const [totalProteins, setTotalProteins] = useState('');
  const [totalSodium, setTotalSodium] = useState('');
  const [totalCholesterol, setTotalCholesterol] = useState('');
  const [totalFiber, setTotalFiber] = useState('');
  const [totalSugar, setTotalSugar] = useState('');
  const [ingredients, setIngredients] = useState(['']); // For handling multiple ingredients
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Fetch the list of restaurants on component mount
  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const restaurantCollection = collection(db, 'restaurants');
        const restaurantSnapshot = await getDocs(restaurantCollection);
        const restaurantList = restaurantSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRestaurants(restaurantList);
        console.log('Fetched restaurants:', restaurantList);
      } catch (error) {
        console.error('Error fetching restaurants:', error);
        setError('Failed to fetch restaurants. Please try again later.');
      }
    };

    fetchRestaurants();
  }, []);

  // Fetch the list of categories for the selected restaurant
  useEffect(() => {
    const fetchCategories = async () => {
      if (selectedRestaurant) {
        try {
          const categoryCollection = collection(db, 'categories');
          const categoryQuery = query(categoryCollection, where('restaurantId', '==', selectedRestaurant));
          const categorySnapshot = await getDocs(categoryQuery);

          if (categorySnapshot.empty) {
            console.log('No matching categories found for restaurant ID:', selectedRestaurant);
            setCategories([]); // Clear categories if none are found
          } else {
            const categoryList = categorySnapshot.docs.map((doc) => doc.data().categoryName);
            setCategories([...new Set(categoryList)]); // Ensure unique categories
            console.log('Fetched categories for restaurant ID:', selectedRestaurant, categoryList);
          }
        } catch (error) {
          console.error('Error fetching categories:', error);
          setCategories([]); // Clear categories in case of an error
        }
      } else {
        setCategories([]); // Clear categories if no restaurant is selected
      }
    };

    fetchCategories();
  }, [selectedRestaurant]);

  // Handle the form submission to add a new food item
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    if (!selectedRestaurant || !itemName || !servingSize || !totalCalories) {
      setError('Please fill in all required fields.');
      return;
    }
    try {
      const foodItemCollection = collection(db, 'foodItems');
      await addDoc(foodItemCollection, {
        restaurantId: selectedRestaurant,
        itemName,
        itemCategory: newCategory || itemCategory, // Use newCategory if provided, otherwise selected category
        servingSize,
        totalCalories,
        totalFats,
        totalCarbs,
        totalProteins,
        totalSodium,
        totalCholesterol,
        totalFiber,
        totalSugar,
        ingredients,
        createdAt: serverTimestamp(), // Add this line to include the timestamp
      });

      // Add new category to Firestore if provided and not already present
      if (newCategory && !categories.includes(newCategory)) {
        const categoryCollection = collection(db, 'categories');
        await addDoc(categoryCollection, {
          restaurantId: selectedRestaurant,
          categoryName: newCategory,
        });

        // Update categories state with the new category
        setCategories((prevCategories) => [...prevCategories, newCategory]);
      }

      setSuccessMessage('Food item added successfully!');
      resetForm();
    } catch (error) {
      setError('Error adding food item: ' + error.message);
    }
  };

  // Reset the form after submission
  const resetForm = () => {
    setSelectedRestaurant('');
    setItemName('');
    setItemCategory('');
    setNewCategory('');
    setServingSize('');
    setTotalCalories('');
    setTotalFats('');
    setTotalCarbs('');
    setTotalProteins('');
    setTotalSodium('');
    setTotalCholesterol('');
    setTotalFiber('');
    setTotalSugar('');
    setIngredients(['']);
    setCategories([]); // Clear categories when resetting form
    setStep(1);
    setError('');
  };

  const handleNextStep = () => {
    if (step === 1 && (!selectedRestaurant || !itemName)) {
      setError('Please select a restaurant and provide an item name before proceeding.');
      return;
    }
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleAddIngredient = () => {
    setIngredients([...ingredients, '']);
  };

  const handleIngredientChange = (index, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index] = value;
    setIngredients(newIngredients);
  };

  // Check if the user is an admin before rendering the page
  if (!isAdmin) {
    return (
      <Container>
        <h1 className="my-4 text-center">Access Denied</h1>
        <p className="text-center">You do not have permission to access this page.</p>
      </Container>
    );
  }

  return (
    <Container>
      <h1 className="my-4 text-center">Add Food Item</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      <Form onSubmit={handleSubmit}>
        {step === 1 && (
          <>
            <Form.Group className="mb-4">
              <Form.Label>Select Restaurant</Form.Label>
              <Form.Control
                as="select"
                value={selectedRestaurant}
                onChange={(e) => setSelectedRestaurant(e.target.value)}
                required
              >
                <option value="">Select a restaurant</option>
                {restaurants.map((restaurant) => (
                  <option key={restaurant.id} value={restaurant.id}>
                    {restaurant.restaurantName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Food Item Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter food item name"
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Food Item Category</Form.Label>
              <Form.Control
                as="select"
                value={itemCategory}
                onChange={(e) => setItemCategory(e.target.value)}
              >
                <option value="">Select a category</option>
                {categories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
                <option value="new">Add a new category...</option>
              </Form.Control>
              {itemCategory === 'new' && (
                <InputGroup className="mt-3">
                  <FormControl
                    placeholder="New Category"
                    value={newCategory}
                    onChange={(e) => setNewCategory(e.target.value)}
                  />
                </InputGroup>
              )}
            </Form.Group>
            <Button variant="primary" onClick={handleNextStep} className="mt-3">
              Next
            </Button>
            <Button variant="secondary" onClick={() => setStep(4)} className="mt-3 ms-2">
              Add Ingredients
            </Button>
          </>
        )}

        {step === 2 && (
          <>
            <Form.Group className="mb-4">
              <Form.Label>Serving Size</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter serving size"
                value={servingSize}
                onChange={(e) => setServingSize(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Total Calories</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter total calories"
                value={totalCalories}
                onChange={(e) => setTotalCalories(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Total Fats (g)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter total fats"
                value={totalFats}
                onChange={(e) => setTotalFats(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Total Carbohydrates (g)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter total carbohydrates"
                value={totalCarbs}
                onChange={(e) => setTotalCarbs(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Total Proteins (g)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter total proteins"
                value={totalProteins}
                onChange={(e) => setTotalProteins(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" onClick={handleNextStep} className="mt-3 me-2">
              Next
            </Button>
            <Button variant="secondary" onClick={handlePreviousStep} className="mt-3">
              Back
            </Button>
          </>
        )}

        {step === 3 && (
          <>
            <Form.Group className="mb-4">
              <Form.Label>Total Sodium (mg)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter total sodium"
                value={totalSodium}
                onChange={(e) => setTotalSodium(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Total Cholesterol (mg)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter total cholesterol"
                value={totalCholesterol}
                onChange={(e) => setTotalCholesterol(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Total Fiber (g)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter total fiber"
                value={totalFiber}
                onChange={(e) => setTotalFiber(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Total Sugars (g)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter total sugar"
                value={totalSugar}
                onChange={(e) => setTotalSugar(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3 me-2">
              Submit
            </Button>
            <Button variant="secondary" onClick={handlePreviousStep} className="mt-3">
              Back
            </Button>
          </>
        )}

        {step === 4 && (
          <>
            <h3 className="mb-4">Add Ingredients</h3>
            <Row>
              {ingredients.map((ingredient, index) => (
                <Col md={4} key={index} className="mb-3">
                  <Form.Group>
                    <Form.Label>Ingredient {index + 1}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={`Enter ingredient ${index + 1}`}
                      value={ingredient}
                      onChange={(e) => handleIngredientChange(index, e.target.value)}
                    />
                  </Form.Group>
                </Col>
              ))}
            </Row>
            <Button variant="secondary" onClick={handleAddIngredient} className="mt-3">
              Add Another Ingredient
            </Button>
            <Button variant="secondary" onClick={() => setStep(1)} className="mt-3 ms-2">
              Back to Main Form
            </Button>
          </>
        )}
      </Form>
    </Container>
  );
}

export default AddItemPage;
