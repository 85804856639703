import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

const BrowserAlert = () => {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    // Check the user agent for Instagram, Facebook, or TikTok
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (
      userAgent.indexOf('FBAN') > -1 ||
      userAgent.indexOf('FBAV') > -1 ||
      userAgent.indexOf('Instagram') > -1 ||
      userAgent.indexOf('TikTok') > -1
    ) {
      setShowAlert(true);
    }
  }, []);

  const handleClose = () => {
    setShowAlert(false);
    // Redirect back to the previous application (e.g., Instagram)
    window.history.back(); // This will take them back to the Instagram app
  };

  return (
    <>
      {showAlert && (
        <Modal show={true} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Open in Browser</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>To continue, please copy the link below and paste it into your browser:</p>
            <div
              style={{
                backgroundColor: '#f1f1f1',
                padding: '10px',
                borderRadius: '5px',
                wordWrap: 'break-word',
              }}
            >
              https://www.trackameal.com
            </div>
            <p className="mt-3">After copying, open your browser and paste the link to continue.</p>
            <Button
              variant="primary"
              onClick={() => {
                navigator.clipboard.writeText('https://www.trackameal.com');
                alert('Link copied to clipboard!');
              }}
            >
              Copy Link
            </Button>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default BrowserAlert;
